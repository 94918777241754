import React from "react"
import styled from "@emotion/styled"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Donate = styled.div`
    position: fixed;
    z-index: 10;
    top: calc(4vh + 120px);
    right: 5.75vw;
    height: 12vh;
    width: 12vh;
    transition: background-color 0.3s ease;
    .donatetext {
        position: fixed;
        top: calc(4vh + 120px);
        right: 5.75vw;
        @media (max-width: 599px) {
            top: calc(6vh + 140px);
            right: 4vw;    
        }
    }
    .donatetext a {
        height: 12vh;
        width: 12vh;
        font-size: 1.2rem;
        letter-spacing: 2px;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        font-family: 'OpticianSans';
        transition: background-color 0.3s ease;
        @media (max-width: 599px) {
            font-size: 1rem;
        }
    }
    .burst-12 {
          background-color: rgba(4,255,255,1);
          width: 100%;
          height: 100%;
          position: relative;
          text-align: center;
          animation: rotating 75s linear infinite;
          transition: all 0.3s ease;
    }
    .burst-12:before,
    .burst-12:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: rgba(4,255,255,1);
          transition: background-color 0.3s ease;
    }
    .burst-12:before {
          transform: rotate(30deg);
    }
    .burst-12:after {
          transform: rotate(60deg);
    }
    &:hover .burst-12, &:hover .burst-12:before, &:hover .burst-12:after {
        background: black;
    }
    &:hover .donatetext a {
        color: white;
    }
    @keyframes rotating {
        100% {
          transform: rotate(-360deg);
        }
    }
    @media (max-width: 599px) {
        height: 10vh;
        width: 10vh;
        top: calc(7vh + 140px);
        right: 6vw;
    }
    `

export default function Right() {
    return (
    <Donate>
        <div className="burst-12" />
        <div className="donatetext"><a href="http://ko-fi.com/gutsyradio" target="_blank" rel="noopener noreferrer">Donate</a></div>
    </Donate>
    )
}