import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import { StoreContext } from "../context/store-context"
import { CartButton } from "./cart-button"
import { Toast } from "./toast"

const Nav = styled.nav`
    position: fixed;
    top: calc(3vh + 120px);
    left: 2vw;
    z-index: 10;
    @media (max-width: 599px) {
        top: 120px;
        left: 0;
        z-index: 5;
        width: 100vw;
    }
`
const List = styled.ul`
    width: 47vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr) .66fr;
    background: #000;
    box-shadow: 0 0 15px rgba(4,255,255,0.75);
    animation: shadowpulse 2s linear 0.5s infinite;

    @keyframes shadowpulse {0% { box-shadow: 0 0 20px rgba(4,255,255,0.75); }50% { box-shadow: 0 0 10px rgba(4,255,255,0.75); }100% { box-shadow: 0 0 20px rgba(4,255,255,0.75); }}
    @media (max-width: 599px) {
        box-sizing: border-box;
        width: 100%;
    }

`

const Item = styled.li`
    list-style: none;
    text-align: center;

    a, button {
        font-family: 'Monod';
        font-size: 1.2rem;
        letter-spacing: -2px;
        color: #fff;
        background-color: transparent;
        text-decoration: none;
        border: 0;
        padding: 1.33vh 0 1.25vh 0;
        display: block;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
        &:hover {
            color: red;
            background-color: white;
            cursor: pointer;
        }
        @media (max-width: 599px) {
          font-size: 1rem;
          padding: 10px 0 7px 0;
        }
    }
    svg {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
    @media (max-width: 599px) {
        font-size: .875rem;
    }
`

const Mainnav = () => {
    const data = useStaticQuery(graphql`
    query {
        home: allPrismicHomepage {
          edges {
            node {
              id
              data {
                sections {
                  nav_title {
                    text
                  }
                  section {
                    document {
                      ... on PrismicPage {
                        id
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }      
    `)
    const { checkout, loading, didJustAddToCart } = React.useContext(StoreContext)

    const items = checkout ? checkout.lineItems : []
  
    const quantity = items.reduce((total, item) => {
      return total + item.quantity
    }, 0)
  
    return (
        <Nav>
        <List>
          <Item><AnchorLink to="/#top" title="Home" stripHash /></Item>
          <Item><AnchorLink to="/#about" title="About" stripHash /></Item>
          <Item><AnchorLink to="/shop" title="Shop" stripHash /></Item>
          <Item><AnchorLink to="/#contact" title="Contact" stripHash /></Item>
          <Item>
          <CartButton quantity={quantity} />
      <Toast show={loading || didJustAddToCart}>
        {!didJustAddToCart ? (
          "Updating…"
        ) : (
          <>
            Added to cart{" "}
            <svg
              width="14"
              height="14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.019 10.492l-2.322-3.17A.796.796 0 013.91 6.304L6.628 9.14a1.056 1.056 0 11-1.61 1.351z"
                fill="#fff"
              />
              <path
                d="M5.209 10.693a1.11 1.11 0 01-.105-1.6l5.394-5.88a.757.757 0 011.159.973l-4.855 6.332a1.11 1.11 0 01-1.593.175z"
                fill="#fff"
              />
              <path
                d="M5.331 7.806c.272.326.471.543.815.163.345-.38-.108.96-.108.96l-1.123-.363.416-.76z"
                fill="#fff"
              />
            </svg>
          </>
        )}
      </Toast>
</Item>
        </List>
        </Nav>
    )
}

export default Mainnav