import React from 'react'
import styled from "@emotion/styled"
import { InstagramLogo, FacebookLogo, ChatText } from "phosphor-react";

const Socialwrap = styled.div`
position: fixed;
top: 1.33vh;
right: .66vw;
z-index: 10;
li {
    list-style: none;
    margin-bottom: 0px;
    height: 32px;
    @media (max-width: 599px) {
        visibility: hidden;
    }
}
a {
    font-size: 18px;
    height: 36px;
    width: 36px;
    color: rgba(255,255,255,0.66);
    background-color: rgba(0,0,0,0.25);
    border-radius: 5px;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease;
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &:hover {
        background-color: #000;
        color: #fff;
    }
}
a .ti-mixcloud {
    &::before {
        content: 'M';
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
`
const Discord = styled.div`
    position: fixed;
    right: 2.5vw;
    bottom: 2.5vw;
    a {
        height: 80px;
        width: 80px;
        display: block;
        border-radius: 80px;
        background-color: #000;
        color: red;
        animation: pulse 2s linear 0.5s infinite;
        @keyframes pulse {0% { transform: scale(1) }50% { transform: scale(1.1) }100% { transform: scale(1) }}.css-6gl6f8 { height: 20px; width: 20px; line-height: 20px; }    
    }
`

const Socialmedia = () => {
    return (
        <Socialwrap>
        <ul>
            <li><a href="https://instagram.com/gutsy_radio" target="_blank" rel="noreferrer"><InstagramLogo size={26} weight="light" /></a></li>
            <li><a href="https://facebook.com/gutsyradio" target="_blank" rel="noreferrer"><FacebookLogo size={26} weight="light" /></a></li>
            <li><a href="https://mixcloud.com/gutsyradio" target="_blank" rel="noreferrer"><span className="ti-mixcloud"></span></a></li>
        </ul>
        <Discord><a href="https://discord.gg/qxsceB8hjk" target="_blank" rel="noreferrer"><ChatText size={36} weight="fill" /></a></Discord>
        </Socialwrap>
    )
}

export default Socialmedia