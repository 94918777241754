import * as React from "react"
import { Link } from 'gatsby-plugin-modal-routing-3'
import { cartButton, badge } from "./cart-button.module.css"
import { ShoppingCart } from "phosphor-react";

export function CartButton({ quantity }) {
  return (
    <Link
      aria-label={`Shopping Cart with ${quantity} items`}
      to="/cart"
      className={cartButton}
      asModal
    >
      <ShoppingCart size={30} />
      {quantity > 0 && <div className={badge}>{quantity}</div>}
    </Link>
  )
}
