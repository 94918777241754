import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { motion } from "framer-motion"

import DonateButton from "../components/donate"
import Mainnav from "../components/mainnav"
import Logo from '../images/gutsy_logo-solo.svg'
import Playerpersist from "../components/player-persist"

const Layoutwrap = styled.div`
margin-top: 130px;
position: relative;
`
const Main = styled.main`
  scroll-snap-type: mandatory;
  scroll-snap-type: y mandatory;
  hr {
    border: 0;
    padding-top: 4vh;
  }
`

const Layout = ({ children }) => {

    return (
      <Layoutwrap >
      
      <header iD="top">
      <Logo css={ css`
            position: fixed;
            left: 0;
            top: calc(0vh + 120px);
            font-size: 0;
            width: 100vw;
            @media (max-width: 599px) {
              top: 165px;
            }
      `} />
      <Mainnav />
      </header>
      <Main>
        <DonateButton />
        <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{
                        type: "spring",
                        mass: 0.35,
                        stiffness: 75,
                        delay: 0.3,
                        duration: 0.3
                      }}
            >
        {children}
        </motion.div>
        
        <Playerpersist />
      </Main>
    </Layoutwrap >
    )
}

export default Layout