import React from "react"
import styled from "@emotion/styled"

import Player from './player'
import Socialmedia from "../components/socialmedia"

const Playerwrap = styled.div`
position: fixed;
z-index: 9;
top: 0;
width: 100vw;
padding: 10px 0;
height: 120px;
display: grid;
grid-template-columns: 3fr 4fr 3fr;
grid-column-gap: 2vw;
align-items: center;
background: rgb(100,100,100);
background: linear-gradient(90deg, rgba(100,100,100,1) 0%, rgba(0,0,0,1) 100%);
@media (max-width: 599px) {
    grid-template-columns: 2fr 1fr;
    align-items: left;
}
> div {
    height: 100px;
    @media (max-width: 599px) {
        padding-top: 0;
        height: 60px;
        margin: 0 auto;
        iframe {
            height: 100%;
            width: 60px;
        }
    }
}
h2 {
    font-family: 'OpticianSans';
    font-size: 2rem;
    letter-spacing: 2px;
    text-align: center;
    transform: translate(-link12px,0);
    line-height: .9em;
    margin-left: 4vw;
    background: linear-gradient(to right, #fff 20%, #ed0800 40%, #ed0800 60%, #fff 80%);
    background-size: 200% auto;
    color: #000;
    overflow-wrap: break-word;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 5s linear infinite;
    @media (max-width: 599px) {
        text-align: left;
        margin-left: 5vw;
        font-size: 1.75rem;
    }
}
h3 {
    color: #fff;
    padding-top: 1vh;
    padding-bottom: 1vh;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "FaktPro-Bold";
    font-size: .75rem;
    position: fixed;
    width: 27.5%;
    z-index: 2;
    @media (max-width: 599px) {
        letter-spacing: .5px;
    }
}

div.listen {
    margin-left: 1vw;
    margin-right: 2vw;
    @media (max-width: 599px) {
        padding: 0 4vw;
        grid-column: 1/3;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2vw;
    }
    h3 {
        color: #fff;
        padding-top: 12px;
        padding-bottom: 1vh;
        font-family: 'OpticianSans';
        font-size: 1.33rem;
        letter-spacing: 1px;
        position: fixed;
        width: 27.5%;
        background: transparent;
        z-index: 2;
        @media (max-width: 599px) {
            position: static;
            width: inherit;
            display: none;
        }
    }
    > ul {
        position: relative;
        top: 44px;
        @media (max-width: 599px) {
            position: static;
        }
    }
    li {
        list-style: none;
        color: #fff;
        font-family: "FaktPro-Normal";
        letter-spacing: .5px;
        margin-bottom: 4px;
        font-size: .9125em;
        color: rgba(255,255,255,0.75);
        &:first-of-type {
            color: #fff;
        }
        a {
            color: rgba(255,255,255,0.8);
            border-bottom: solid 1px rgba(255,255,255,0.25);
            transition: all 0.3s ease;
            &:hover {
                color: red;
                border-bottom-color: red;
            }
        }
        @media (max-width: 599px) {
            font-size: .8125rem;
        }
    }
}
@keyframes shine {
    to {
      background-position: 200% center;
    }
}

`

const Playerpersist = () => {
    return (
        <div>
        <Playerwrap>
            <h2>Listen Live! →</h2>
            <div><Player /></div>
            <div className="listen">
                <h3>Listen on the go →</h3>
                <ul>
                    <li><a href="https://apps.apple.com/us/app/gutsy-radio/id1565979117" target="_blank" rel="noreferrer">Gutsy Radio iOS app</a></li>
                    <li><a href="https://s2.radio.co/s369d6f962/listen" target="_blank" rel="noreferrer">Listen to live feed</a></li>
                </ul>
            </div>
        </Playerwrap>
        <Socialmedia />
        </div>
    )
}

export default Playerpersist