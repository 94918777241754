import React from "react"
import Iframe from 'react-iframe'
import MediaQuery, { useMediaQuery } from 'react-responsive'

export default function Player() {
    const isDesktop = useMediaQuery({ query: '(min-width: 600px)' })

    return (
        <>
        <MediaQuery minWidth={600}>
        <Iframe url="https://embed.radio.co/player/073c831.html"
        width="100%"
        allow="autoplay"
        scrolling="no"
        style="border: none; overflow: hidden; width: 100%; margin: 0px auto 4vh auto; height: 100px;"/>
        </MediaQuery>
        <MediaQuery maxWidth={599}>
        <Iframe url="https://embed.radio.co/player/2d2386c.html"
        width="100%"
        allow="autoplay"
        scrolling="no"
        style="border: none; overflow: hidden; width: 100%; margin: 0px auto 4vh auto; height: 100px;"/>
        </MediaQuery>
        </>

    )
}